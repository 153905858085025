import React from 'react'
import { Navbar } from '../../components/Navbar/Navbar'
import BlogsHome from '../../components/Blogs/BlogsHome'

function Blogs() {
  return (
    <div className='blogs'>
      <Navbar/>
      <BlogsHome/>
    </div>
  )
}

export default Blogs
