import React, { useState } from "react";
import "./BlogsHome.css";
import blog_bg_image from "../../assets/images/blog_bg_image.png";
import Blog_author from "../../assets/images/MRShreyaank.png";

const BlogsHome = () => {
  const [activeTab, setActiveTab] = useState("section1");

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    setActiveTab(id);
  };

  return (
    <div className="blog-container">
      <div className="right-section">
        <img src={blog_bg_image} alt="Blog" className="blog-img" />

        <div className="blog-content">
          <section id="section1" className="blog-section">
            <div className="blog-caption">
              <h1>CURIOSITY MEETS PURPOSE</h1>
            </div>
            <h2>Introduction: A Pursuit of Innovation and Balance</h2>
            <p>
              The inspiration to embark on these research endeavors arose from
              my curiosity to address real-world challenges through innovation
              and a passion for making an impact beyond professional boundaries.
              Two key moments drove me towards these topics:
            </p>
            <ul>
              <li>
                <b>The Challenge of Secure Machine Learning:</b> In my role as a
                professional working with machine learning, I noticed the
                growing threat of adversarial attacks, particularly poisoning
                attacks that compromise system integrity. This gap ignited my
                interest in exploring robust solutions to safeguard critical
                applications like modulation detection.
              </li>
              <li>
                <b>The Intersection of Technology and Mental Well-Being:</b> A
                personal interest in the power of sound therapy and its effects
                on mental health, combined with the rising prevalence of
                depression, motivated me to investigate the efficacy of ancient
                practices such as Vedic chants and music. I was fascinated by
                how data-driven analysis could scientifically validate these
                age-old methods and offer alternative approaches to modern
                psychological challenges.
              </li>
            </ul>
            <p>
              Balancing full-time work with this ambitious pursuit required
              focus, determination, and a clear vision of the impact these
              projects could have on both the technical and societal fronts.
            </p>
          </section>

          <section id="section2" className="blog-section">
            <h2>Motivation: Where Curiosity Meets Purpose</h2>
            <p>
              The inspiration for these papers stemmed from two distinct yet
              equally intriguing fields—modulation detection in communication
              systems and the therapeutic effects of sound on mental health.
            </p>
            <ul>
              <li>
                <b>
                  Enhancing Support Vector Machines Against Poisoning Attacks:
                </b>
                In today’s interconnected world, ensuring the robustness of
                machine learning models is crucial. The increasing
                sophistication of adversarial attacks, especially poisoning
                attacks, poses a significant threat to communication systems. My
                fascination with secure communication and machine learning's
                role in enabling it drove me to explore how noise augmentation
                could enhance Support Vector Machines (SVMs) for robust
                modulation detection.
              </li>
              <li>
                <b>Psychoacoustic Wellness:</b>
                On a topic close to my nature, I have always been captivated by
                the profound impact of sound and music on mental well-being.
                Amid the growing mental health crisis, particularly depression,
                I sought to scientifically validate the efficacy of ancient
                Vedic chants and music as therapeutic tools. This
                interdisciplinary approach combined psychology, acoustics, and
                data-driven analysis, aligning my technical expertise with a
                socially impactful cause.
              </li>
            </ul>
          </section>

          <section id="section3" className="blog-section">
            <h2>The Process: Juggling Work, Research, and Passion</h2>
            <p>
              Balancing the rigor of research with professional commitments
              required effective time management, strategic planning, and a
              supportive work environment.
            </p>
            <ul>
              <li>
                <b>A Supportive Work Environment:</b>
                My managers Mr Shabarinath Kande and Mr Nagesh Balivada played a
                pivotal role in my journey, offering flexibility during critical
                research phases and encouraging me to pursue my passion for
                innovation. Their understanding of the importance of personal
                growth beyond work responsibilities empowered me to give my
                best.
              </li>
            </ul>
          </section>

          <section id="section4" className="blog-section">
            <h2>The Research Highlights</h2>
            <p>
              <b>Paper 1:</b> Enhancing Support Vector Machines Against
              Poisoning Attacks
            </p>
            <ul>
              <li>
                <b>Approach:</b> Introduced a noise-augmentation technique to
                enhance SVM robustness against adversarial poisoning attacks,
                with a focus on modulation classification.
              </li>
              <li>
                <b>Findings:</b> The results demonstrated a 50% improvement in
                the accuracy and resilience of SVMs at a mere 50 noise points of
                white gaussian noise, even in low Signal to Noise Ratio (SNR)
                environments. This study offers a promising direction for secure
                machine learning in communication systems.
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1QPwcsLu5uXW7CC31AZ9uD1C8yOvRisD_/view?usp=sharing">
                  Paper Link
                </a>
              </li>
            </ul>
            <p>
              <b>Paper 2:</b> Psychoacoustic Wellness
            </p>
            <ul>
              <li>
                <b>Approach:</b> Conducted experiments analyzing the effects of
                Vedic chants and music on patients diagnosed with mild to
                moderate depression. The study utilized EEG signals,
                specifically analyzing alpha and beta brainwave activity. Alpha
                waves (8–12 Hz) are significant as they are typically associated
                with relaxation and calm states. Beta waves (13–30 Hz), on the
                other hand, are linked to active cognitive engagement and
                attention, making them crucial for understanding states of
                alertness and focused mental activity.
              </li>
              <li>
                <b>Findings:</b> The research revealed a twofold increase in
                alpha of the EEG signal when a subject is exposed to
                rejuvenating and calming chants suggesting that sound therapy
                could complement traditional treatments.
              </li>
              <li>
                <a href="https://drive.google.com/file/d/1Hwawee9Zv4eP9hfXa1PXVdl8-P-edyLG/view?usp=sharing">
                  Paper Link
                </a>
              </li>
            </ul>
          </section>
          <section id="section5" className="blog-section">
            <h2>Results and Impact</h2>
            <p>
              Seeing both papers accepted at international conferences was an
              immensely rewarding experience. It validated the countless hours
              of effort and opened doors to meaningful conversations with
              experts from diverse fields.
            </p>
            <ul>
              <li>
                <b>Technical Impact:</b> My first paper has the potential to
                influence the design of secure machine learning systems in
                critical applications such as satellite communication.
              </li>
              <li>
                <b>Societal Impact:</b> The second paper advocates for
                integrating ancient wisdom with modern psychology, offering an
                alternative avenue for mental health intervention.
              </li>
            </ul>
          </section>
          <section id="section6" className="blog-section">
            <h2>Key Takeaways: Lessons from the Journey </h2>
            <ul>
              <li>
                <b>Motivation Matters:</b> Rooting research in personal
                curiosity and societal relevance makes the journey deeply
                fulfilling
              </li>
              <li>
                <b> Time Management is Key:</b> Breaking down tasks into smaller
                milestones keeps progress steady, even amidst a busy schedule.
              </li>
              <li>
                <b>The Power of a Supportive Environment:</b> Having an
                understanding manager or mentor can make a world of difference
                in pursuing personal goals alongside professional
                responsibilities.
              </li>
              <li>
                <b>Interdisciplinary Collaboration Yields Innovation:</b>{" "}
                Combining diverse expertise unlocks unique solutions to complex
                problems.
              </li>
            </ul>
          </section>
          <section id="section7" className="blog-section">
            <h2>Conclusion: Bridging Passion and Profession</h2>
            <p>
              Publishing these papers was not just a milestone but a testament
              to the power of perseverance and curiosity. By aligning my
              professional expertise with meaningful causes, I discovered how
              innovation can thrive even under constraints.
            </p>
          </section>
        </div>
      </div>
      <div className="left-section">
        <div className="author-info">
          <img src={Blog_author} alt="Author" className="author-img" />
          <h3>M R Shreyaank</h3>
          <div className="author-des">
            <p>My Experience Publishing Two International Conference Papers.</p>
            <p className="author-text">
              The inspiration to embark on these research endeavors arose from
              my curiosity to address real-world challenges through innovation
              and a passion for making an impact beyond professional boundaries.
            </p>
          </div>
        </div>
        <div className="tabs">
          <i>In This Article</i>
          <p
            className={activeTab === "section1" ? "active" : ""}
            onClick={() => scrollToSection("section1")}
          >
            Introduction: A Pursuit of Innovation and Balance
          </p>
          <p
            className={activeTab === "section2" ? "active" : ""}
            onClick={() => scrollToSection("section2")}
          >
            Motivation: Where Curiosity Meets Purpose
          </p>
          <p
            className={activeTab === "section3" ? "active" : ""}
            onClick={() => scrollToSection("section3")}
          >
            The Process: Juggling Work, Research, and Passion
          </p>
          <p
            className={activeTab === "section4" ? "active" : ""}
            onClick={() => scrollToSection("section4")}
          >
            The Research Highlights
          </p>
          <p
            className={activeTab === "section5" ? "active" : ""}
            onClick={() => scrollToSection("section5")}
          >
            Results and Impact
          </p>
          <p
            className={activeTab === "section6" ? "active" : ""}
            onClick={() => scrollToSection("section6")}
          >
            Key Takeaways: Lessons from the Journey
          </p>
          <p
            className={activeTab === "section7" ? "active" : ""}
            onClick={() => scrollToSection("section7")}
          >
            Conclusion: Bridging Passion and Profession
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogsHome;
